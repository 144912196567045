// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  // blog: getIcon('ic_blog'),
  // cart: getIcon('ic_cart'),
  // chat: getIcon('ic_chat'),
  // mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  users: getIcon('ic_users'),
  // kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  // calendar: getIcon('ic_calendar'),
  // ecommerce: getIcon('ic_ecommerce'),
  // analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  dollar: getIcon('ic_dollar_new'),
  reciept: getIcon('ic_reciept'),
  pound: getIcon('ic_pound'),
  euro: getIcon('ic_euro'),
  // booking: getIcon('ic_booking'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      // { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'Banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'Transactions', path: PATH_DASHBOARD.general.transaction, icon: ICONS.dashboard },
      { title: 'Requests', path: PATH_DASHBOARD.general.requests, icon: ICONS.reciept },
      { title: 'Clients', path: '/dashboard/clients/all', icon: ICONS.users },
      { title: 'Profile', path: '/dashboard/profile', icon: ICONS.user },
      { title: 'Referrals & Rewards', path: '/dashboard/referrals', icon: ICONS.dollar },
      // { title: 'ReKYC', path: '/dashboard/rekyc', icon: ICONS.user },

      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },
  // {
  //   subheader: 'Accounts',
  //   items: [], // ! GET ICON FLAGS
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    // subheader: 'management',
    items: [
      // MANAGEMENT : USER
      // {
      //   title: 'user',
      //   path: PATH_DASHBOARD.user.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //     { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //     { title: 'list', path: PATH_DASHBOARD.user.list },
      //     { title: 'create', path: PATH_DASHBOARD.user.newUser },
      //     { title: 'edit', path: PATH_DASHBOARD.user.editById },
      //     { title: 'account', path: PATH_DASHBOARD.user.account },
      //   ],
      // },
      // MANAGEMENT : E-COMMERCE
      // {
      //   title: 'e-commerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //     { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
      //   ],
      // },
      // MANAGEMENT : BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.postById },
      //     { title: 'new post', path: PATH_DASHBOARD.blog.newPost },
      //   ],
      // },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: (
  //         <Label variant="outlined" color="error">
  //           +32
  //         </Label>
  //       ),
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //     },
  //   ],
  // },
];

export default navConfig;
